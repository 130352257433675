import React, { useState } from "react";
import "./index";
import screambear from "./ScreambearEmpty.gif";
import doodahman from "./IMG_0172.gif";

function App() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasGuessedWrong, setHasGuessedWrong] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isDarkMode] = useState(false);
  const [email, setEmail] = useState("");

  const handleChange = (event) => {
    if (event.key === "Enter" && event.target.value) {
      const enteredPassword = event.target.value
        .split(" ")
        .join("")
        .toLowerCase();
      if (enteredPassword === process.env.REACT_APP_PASSWORD?.toLowerCase()) {
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
        setHasGuessedWrong(true);
        setTimeout(() => {
          setHasGuessedWrong(false);
        }, 500);
      }
    }
  };

  const storeEmail = (value) => {
    setEmail(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let formData = new FormData(event.target);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setIsFormSubmitted(true);
      })
      .catch((error) => alert(error));
  };

  // const toggleDarkMode = () => {
  //   setIsDarkMode(!isDarkMode);
  // };

  // let theBears = [
  //   <div className="the-bears" style={{ top: `20%` }}></div>,
  //   // <div className="the-bears" style={{ top: `${1 * 7}em` }}></div>,
  //   // <div className="the-bears" style={{ top: `${2 * 7}em` }}></div>,
  //   // <div className="the-bears" style={{ top: `${3 * 7}em` }}></div>,
  //   // <div className="the-bears" style={{ top: `${4 * 7}em` }}></div>,
  //   // <div className="the-bears" style={{ top: `${5 * 7}em` }}></div>,
  //   // <div className="the-bears" style={{ top: `${6 * 7}em` }}></div>,
  //   // <div className="the-bears" style={{ top: `${7 * 7}em` }}></div>,
  // ];

  return (
    <>
      {/* <div
        style={{
          position: "fixed",
          top: "1em",
          left: "1em",
          zIndex: 2,
          color: isDarkMode ? "white" : "black",
        }}
      >
        <label>
          Dark
          <input
            type="checkbox"
            checked={isDarkMode}
            onChange={toggleDarkMode}
            label="dark"
          />
        </label>
      </div> */}
      <div className={`wrapper ${isDarkMode && "dark"}`}>
        {/* {theBears} */}
        <div className="top-container">
          <div className="header">
            <a
              href="https://rarible.com/shakedown"
              target="_blank"
              rel="noreferrer"
            >
              <div>{"(~);}"}</div>
              <div>Crypto Jerry's</div>
            </a>
          </div>

          <div className="buy-with-crypto">
            <a
              href="https://commerce.coinbase.com/checkout/4a3f457d-b33a-47bc-8599-b9b03093819f"
              target="_blank"
              rel="noreferrer"
            >
              <div className="crypto-jerry-hat" />
              {/* <img
                src={cryptoJerrysHatGif}
                alt="Click here to buy a crypto jerry's hat"
              /> */}
            </a>
          </div>
        </div>

        {isSuccess && <img src={screambear} alt="screambear" width="300" />}

        {!isSuccess && (
          <>
            {/* <div
                style={{
                  backgroundImage: `url(${screambear})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  width: "10em",
                  height: "10em",
                  display: "inline-block",
                }}
              ></div> */}
            <div
              className={`middle-container secret-container ${
                hasGuessedWrong && "has-guessed-wrong"
              }`}
            >
              <div className="bear-container">
                <div
                  className="one-bear"
                  style={{ width: "5em", height: "5em" }}
                ></div>
                <div
                  className="one-bear"
                  style={{ width: "5em", height: "5em" }}
                ></div>
                <div
                  className="one-bear"
                  style={{ width: "5em", height: "5em" }}
                ></div>
              </div>

              <div className="secret-phase-form">
                <label htmlFor="passcode">
                  Only the answer to the riddle will unlock the prize:
                </label>
                <div style={{ display: "block", margin: "0 auto" }}>
                  <div className="one-bear"></div>
                  <input
                    className="secret-input input"
                    type="password"
                    name="passcode"
                    placeholder="type answer here"
                    onKeyDown={handleChange}
                  />
                  <div className="one-bear"></div>
                </div>
              </div>
            </div>
          </>
        )}

        {!isSuccess && isFormSubmitted && (
          <div className="bottom-container">
            <img
              className="doo-dah-man"
              src={doodahman}
              alt="The doo dah man walks across the screen"
            />
            <p>Thank you, your email has been sent to The Doo Dah Man.</p>
          </div>
        )}

        {!isSuccess && !isFormSubmitted && (
          <div className="bottom-container">
            <form
              name="contact"
              method="post"
              action="/"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact" />
              <p>
                <label htmlFor="email">If you want to know more: </label>
                <input
                  className="input"
                  type="email"
                  name="email"
                  placeholder="enter your@email.com"
                  onChange={storeEmail}
                />
                <button disabled={!email} type="submit">
                  Subscribe
                </button>
              </p>
            </form>
          </div>
        )}
      </div>
      <script src="https://commerce.coinbase.com/v1/checkout.js?version=201807"></script>
    </>
  );
}

export default App;
